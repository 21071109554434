import { InputAdornment } from '@mui/material';
import styled, { css } from 'styled-components';
import InputField from '../InputField';
export const InputFieldStyled = styled(InputField) `
  border-radius: 6px;
  padding: 0;
  position: relative;

  ${({ borderColor }) => borderColor &&
    css `
      border-color: ${borderColor};
    `}

  & .MuiInputLabel-shrink {
    padding: 1px 3px;
    background-color: black;
    margin-bottom: 3px;
  }

  ${({ placeholder }) => placeholder &&
    css `
      & .MuiSelect-select .notranslate::after {
        content: '${placeholder}';
        opacity: 0.42;
      }
    `}

  & .MuiFormHelperText-root {
    position: absolute;
    bottom: -24px;
    left: 0;
    margin: 0;

    ${({ theme, error }) => css `
      font-size: 14px;
      font-family: 'Poppins';
      color: ${error ? theme.palette.error.main : 'black'};
    `}
  }

  & .MuiInputBase-root {
    ${({ $padding }) => $padding
    ? css `
            padding: ${$padding};
          `
    : css `
            padding: 0 14px;
          `}
    ${({ multiline }) => multiline &&
    css `
        padding-right: 0;
        height: auto;
      `}

    ${({ theme, borderColor, error, color = 'primary' }) => css `
      outline: 1px solid
        ${borderColor
    ? error
        ? theme.palette.error.main
        : borderColor
    : theme.palette[error ? 'error' : color].main};
      font-size: 15px;
      font-family: 'Poppins';
    `}

    & .MuiInputBase-input {
      padding: 15px;
      border-radius: 0;
      padding-left: 8px;
      font-family: 'Poppins';

      & textarea {
        height: 200px !important;
        overflow-y: scroll;
      }

      ${({ multiline }) => multiline &&
    css `
          padding: 8px;
          height: auto;
          max-height: 200px;
          overflow: auto;
        `}
    }

    & .MuiSelect-icon {
      margin-top: 8px;
      margin-right: 5px;
    }
  }
`;
export const FieldAdornment = styled(InputAdornment) `
  ${({ needSeparateLine }) => css `
    ${needSeparateLine &&
    css `
      border-right: 1px solid #8f8f8f;
    `}

    height: 100%;

    & .MuiSvgIcon-root {
      width: 24px;
      height: 24px;
      color: #8f8f8f;

      ${needSeparateLine &&
    css `
        margin-right: 10px;
      `}
    }
  `}
`;
