import { AppBar, Box, List, ListItemButton, Toolbar } from '@mui/material';
import styled, { css } from 'styled-components';
export const Logo = styled.img `
  max-height: 38px;
  max-width: 117px;
  object-fit: cover;
  mix-blend-mode: multiply;
`;
export const Title = styled.h1 `
  color: black;
  font-size: 25;
  text-align: center;
  margin: 0;
  padding: 0;
  text-transform: none;
`;
export const CustomButton = styled.button `
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
  transition: border 0.3s ease;
  border-radius: 5px;
  border: 3px solid transparent;

  display: flex;
  gap: 4px;
`;
export const ListStyled = styled(List) `
  ${() => css `
    & li {
      padding: 0;
    }

    & li button {
      font-size: 15px;
    }
  `}
`;
export const StyledAppBar = styled(AppBar) `
  position: sticky;
  background-color: white;
  height: 60px;
  box-shadow: none;
`;
export const StyledToolbar = styled(Toolbar) `
  height: 60px;
  min-height: 60px !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
`;
export const FlexBox = styled(Box) `
  flex-direction: row;
  display: flex;
  gap: 10px;
`;
export const ItemButton = styled(ListItemButton).attrs(() => ({
    component: 'button',
})) `
  white-space: nowrap;
`;
