import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import 'react-data-grid/lib/styles.css';
import { useCallback } from 'react';
import Modal from 'react-modal';
import { ArrowDownIcon, LocationIcon } from 'src/assets/icons';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { closeIcon } from 'src/assets';
import { useAddDonate } from 'src/api/hooks/useAddDonate';
import { useCountryes } from 'src/api/hooks/useCountryes';
import { ItemPickerStyled, DonateFormWrapper, ModalContent, ModalTitle, HeaderConatiner, CloseButton, CloseImage, modalStyles, } from './styled';
import { BUTTON_TEXT, MODAL_TITLE, defaultValues, houseAmount, validationSchema, } from './lib/constatns';
import { generateHousesSelectorData } from './lib/helpers';
import SubmitButton from '@/ui/SubmitButton';
import { useUser } from '@/hooks/useUserData';
export default function AddDonation({ modalIsOpen, setModalIsOpen, handleSubmite, }) {
    const { addDonate } = useAddDonate();
    const { userId } = useUser();
    const { countries } = useCountryes();
    const houses = generateHousesSelectorData(houseAmount);
    const { handleSubmit, control, formState: { errors, isValid }, } = useForm({
        defaultValues,
        resolver: zodResolver(validationSchema),
    });
    const submit = useCallback(async (formData) => {
        if (!isValid)
            return null;
        await addDonate(userId, formData.country, formData.houses);
        setModalIsOpen(false);
        handleSubmite();
    }, [addDonate, isValid, handleSubmite, setModalIsOpen, userId]);
    return (_jsx(Modal, { isOpen: modalIsOpen, style: modalStyles, onRequestClose: () => {
            setModalIsOpen(false);
        }, children: _jsxs(ModalContent, { children: [_jsxs(HeaderConatiner, { children: [_jsx(ModalTitle, { children: MODAL_TITLE }), _jsx(CloseButton, { onClick: () => {
                                setModalIsOpen(false);
                            }, children: _jsx(CloseImage, { src: closeIcon }) })] }), _jsxs(DonateFormWrapper, { onSubmit: handleSubmit(submit), children: [_jsx(Controller, { name: 'country', control: control, render: ({ field: { ref, onChange, ...field } }) => (_jsx(ItemPickerStyled, { inputRef: ref, fullWidth: true, popupIcon: _jsx(ArrowDownIcon, {}), onAutoCompleteChange: onChange, onChange: onChange, error: !!errors.country, dataOptions: countries, helperText: errors.country?.message, placeholder: 'Country', Icon: LocationIcon, ...field })) }), _jsx(Controller, { name: 'houses', control: control, render: ({ field: { ref, onChange, ...field } }) => (_jsx(ItemPickerStyled, { inputRef: ref, fullWidth: true, popupIcon: _jsx(ArrowDownIcon, {}), onAutoCompleteChange: onChange, onChange: onChange, error: !!errors.houses, helperText: errors.houses?.message, dataOptions: houses, placeholder: 'Houses', ...field })) }), _jsx(SubmitButton, { width: 292, height: 48, fontWeight: 900, children: BUTTON_TEXT })] })] }) }));
}
