import { useEffect, useRef, useState } from 'react';
import axiosInstance from '@/env/http';
import RequestService from '@/utils/request';
async function fetchData(currentC) {
    try {
        const response = await axiosInstance.post('ApiCall', {
            p: RequestService.buildRequest('J_All_XY', currentC),
        });
        if (!response.data?.length) {
            return [];
        }
        return response.data;
    }
    catch (error) {
        console.error('Error fetching data:', error);
        return null;
    }
}
export const useDataGrid = () => {
    const [data, setData] = useState([]);
    const [error, setError] = useState(null);
    const currentCRef = useRef(0);
    const [isEnd, setIsEnd] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const fetchDataAndUpdateState = () => {
        setIsLoading(true);
        setError(null);
        fetchData(currentCRef.current)
            .then((responseData) => {
            if (responseData) {
                if (responseData.length > 0) {
                    setData((prevData) => [...prevData, ...responseData]);
                    currentCRef.current = responseData[responseData.length - 1].C;
                }
                else {
                    setIsEnd(true);
                }
            }
            else {
                setError('Server Error');
            }
        })
            .catch((error) => {
            console.error('Error fetching data:', error);
            setError('Server Error');
        })
            .finally(() => {
            setIsLoading(false);
        });
    };
    useEffect(() => {
        fetchDataAndUpdateState();
    }, []);
    const loadMore = () => {
        if (!isEnd && !isLoading && data.length !== 0) {
            fetchDataAndUpdateState();
        }
    };
    return { data, isEnd, loadMore, isLoading, error };
};
