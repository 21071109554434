import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import 'react-data-grid/lib/styles.css';
import DataGrid from 'react-data-grid';
import { createContext, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { plus } from 'src/assets';
import { useFormik } from 'formik';
import { useDataGrid } from 'src/api/hooks/useDataGrid';
import { AddButton, AddContainer, AddFilrerContainer, AddItemButton, AddItemButtonImage, AddItemInput, ColumnName, ErrorMessage, ErrorText, FilterColumn, FilterContaier, Input, Page, RootContainer, Spinner, SpinnerContainer, Toolbar, } from './styled';
import { validationSchema } from './lib/constats';
const FilterContext = createContext(undefined);
function inputStopPropagation(event) {
    if (['ArrowLeft', 'ArrowRight'].includes(event.key)) {
        event.stopPropagation();
    }
}
function FilterRenderer({ tabIndex, column, children, }) {
    const filters = useContext(FilterContext);
    return (_jsxs(FilterContaier, { children: [_jsx(ColumnName, { children: column.name }), filters.enabled && _jsx(_Fragment, { children: children({ tabIndex, filters }) })] }));
}
function AddItemRenderer({ tabIndex, children, }) {
    const filters = useContext(FilterContext);
    return (_jsx(AddFilrerContainer, { children: filters && _jsx(_Fragment, { children: children({ tabIndex, filters }) }) }));
}
export default function DataGridPage() {
    const { data, loadMore, isLoading, error } = useDataGrid();
    const [isOpenAddNewItem, setIsOpenAddNewItem] = useState(false);
    const [isSubmited, setIsSubmited] = useState(false);
    const dataGridRef = useRef(null);
    useEffect(() => {
        if (data.length < 40) {
            loadMore();
        }
    }, [data]);
    console.log(data);
    const formik = useFormik({
        initialValues: {
            NewSubUserLoginC: "",
            NewSecondNo: "",
            NewX: "",
            NewY: "",
        },
        validationSchema,
        onSubmit: (values) => {
            alert(`
        New Item sent
        Date: ${values.NewSubUserLoginC}\n
        Seconds: ${values.NewSecondNo}\n
        Program name: ${values.NewX}\n
        Question: ${values.NewY}\n
      `);
            formik.resetForm();
        },
    });
    const rows = data.map((item, index) => ({
        id: index,
        ...item,
    }));
    const summaryRows = useMemo(() => [
        {
            id: 'total_0',
            totalCount: rows.length,
        },
    ], [rows]);
    useEffect(() => {
        document.body.style.overflow = 'hidden';
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, []);
    const [filters, setFilters] = useState(() => ({
        SubUserLoginC: '',
        SecondNo: '',
        X: '',
        Y: '',
        enabled: true,
    }));
    const developerOptions = useMemo(() => Array.from(new Set(rows.map((r) => r.SubUserLoginC))).map((d) => ({
        label: d,
        value: d,
    })), [rows]);
    const columns = useMemo(() => [
        {
            key: 'C',
            name: 'ID',
            width: 50,
            renderHeaderCell: (p) => (_jsx(FilterRenderer, { ...p, children: () => _jsx(Input, { style: { display: 'none' } }) })),
            renderSummaryCell() {
                return (_jsx(AddContainer, { children: _jsx(AddButton, { onClick: () => {
                            setIsSubmited(true);
                        }, type: 'submit', children: _jsx(AddItemButtonImage, { src: plus, alt: 'Add item' }) }) }));
            },
        },
        {
            key: 'SubUserLoginC',
            name: 'Sub User Login Id',
            headerCellClass: FilterColumn,
            renderHeaderCell: (p) => (_jsx(FilterRenderer, { ...p, children: ({ filters, ...rest }) => (_jsx(Input, { ...rest, value: filters.SubUserLoginC, onChange: (e) => setFilters({
                        ...filters,
                        SubUserLoginC: e.target.value,
                    }), onKeyDown: inputStopPropagation })) })),
            renderSummaryCell: (p) => (_jsx(AddItemRenderer, { ...p, children: ({ filters, ...rest }) => (_jsxs(AddContainer, { children: [formik.touched.NewSubUserLoginC && formik.errors.NewSubUserLoginC ? (_jsx(ErrorText, { children: formik.errors.NewSubUserLoginC })) : null, _jsx(AddItemInput, { ...rest, name: 'NewSubUserLoginC', value: formik.values.NewSubUserLoginC, onChange: formik.handleChange, onKeyDown: inputStopPropagation })] })) })),
        },
        {
            key: 'SecondNo',
            name: 'Second No',
            headerCellClass: FilterColumn,
            renderHeaderCell: (p) => (_jsx(FilterRenderer, { ...p, children: ({ filters, ...rest }) => (_jsx(Input, { ...rest, value: filters.SecondNo, onChange: (e) => setFilters({
                        ...filters,
                        SecondNo: e.target.value,
                    }), onKeyDown: inputStopPropagation })) })),
            renderSummaryCell: (p) => (_jsx(AddItemRenderer, { ...p, children: ({ filters, ...rest }) => (_jsxs(AddContainer, { children: [formik.touched.NewSecondNo && formik.errors.NewSecondNo ? (_jsx(ErrorText, { children: formik.errors.NewSecondNo })) : null, _jsx(AddItemInput, { ...rest, name: 'NewSecondNo', value: formik.values.NewSecondNo, onChange: formik.handleChange, onKeyDown: inputStopPropagation })] })) })),
        },
        {
            key: 'X',
            name: 'X',
            headerCellClass: FilterColumn,
            renderHeaderCell: (p) => (_jsx(FilterRenderer, { ...p, children: ({ filters, ...rest }) => (_jsx(Input, { ...rest, value: filters.X, onChange: (e) => setFilters({
                        ...filters,
                        X: e.target.value,
                    }), onKeyDown: inputStopPropagation })) })),
            renderSummaryCell: (p) => (_jsx(AddItemRenderer, { ...p, children: ({ filters, ...rest }) => (_jsxs(AddContainer, { children: [formik.touched.NewX && formik.errors.NewX ? (_jsx(ErrorText, { children: formik.errors.NewX })) : null, _jsx(AddItemInput, { ...rest, name: 'NewX', value: formik.values.NewX, onChange: formik.handleChange, onKeyDown: inputStopPropagation })] })) })),
        },
        {
            key: 'Y',
            name: 'Y',
            headerCellClass: FilterColumn,
            renderHeaderCell: (p) => (_jsx(FilterRenderer, { ...p, children: ({ filters, ...rest }) => (_jsx(Input, { ...rest, value: filters.Y, onChange: (e) => setFilters({
                        ...filters,
                        Y: e.target.value,
                    }), onKeyDown: inputStopPropagation, list: 'ProgramNms' })) })),
            renderSummaryCell: (p) => (_jsx(AddItemRenderer, { ...p, children: ({ filters, ...rest }) => (_jsxs(AddContainer, { children: [formik.touched.NewY && formik.errors.NewY ? (_jsx(ErrorText, { children: formik.errors.NewY })) : null, _jsx(AddItemInput, { ...rest, name: 'NewY', value: formik.values.NewY, onChange: formik.handleChange, onKeyDown: inputStopPropagation })] })) })),
        },
    ], [formik.errors.NewSecondNo,
        formik.errors.NewSubUserLoginC,
        formik.errors.NewX,
        formik.errors.NewY,
        formik.handleChange,
        formik.touched.NewSecondNo,
        formik.touched.NewSubUserLoginC,
        formik.touched.NewX,
        formik.touched.NewY,
        formik.values.NewSecondNo,
        formik.values.NewSubUserLoginC,
        formik.values.NewX,
        formik.values.NewY]);
    const filteredRows = useMemo(() => rows.filter((r) => (filters.SecondNo
        ? r.SecondNo.toLowerCase().startsWith(filters.SecondNo.toLowerCase())
        : true) &&
        (filters.SubUserLoginC
            ? r.SubUserLoginC.toLowerCase().startsWith(filters.SubUserLoginC.toLowerCase())
            : true) &&
        (filters.X
            ? r.X.toLowerCase().startsWith(filters.X.toLowerCase())
            : true) &&
        (filters.Y
            ? r.Y.toLowerCase().startsWith(filters.Y.toLowerCase())
            : true)), [rows, filters]);
    const [isBottom, setIsBottom] = useState(false);
    useEffect(() => {
        if (isBottom) {
            loadMore();
            setIsBottom(false);
        }
    }, [isBottom, loadMore]);
    function clearFilters() {
        setFilters({
            SecondNo: '',
            SubUserLoginC: '',
            X: '',
            Y: '',
            enabled: true,
        });
    }
    function handleSetIsOpenAddNewItem() {
        setIsOpenAddNewItem(!isOpenAddNewItem);
        formik.resetForm();
    }
    function toggleFilters() {
        setFilters((filters) => ({
            ...filters,
            enabled: !filters.enabled,
        }));
    }
    function handleScroll(event) {
        const { scrollHeight, scrollTop, clientHeight } = event.target;
        if (scrollHeight - scrollTop === clientHeight || scrollHeight - scrollTop - 1 <= clientHeight) {
            setIsBottom(true);
        }
    }
    return (_jsx(Page, { id: 'data-grid-page', children: _jsx("form", { onSubmit: formik.handleSubmit, children: _jsxs(RootContainer, { children: [_jsxs(Toolbar, { children: [_jsx("button", { type: 'button', onClick: toggleFilters, children: "Toggle Filters" }), _jsx("button", { type: 'button', onClick: clearFilters, children: "Clear Filters" }), _jsx(AddItemButton, { type: 'button', onClick: handleSetIsOpenAddNewItem, children: _jsx(AddItemButtonImage, { src: plus, alt: 'Add item' }) })] }), error ? _jsx(ErrorMessage, { children: error }) :
                        _jsxs(_Fragment, { children: [_jsx(FilterContext.Provider, { value: filters, children: _jsx(DataGrid, { ref: dataGridRef, onScroll: handleScroll, className: filters.enabled ? FilterColumn : undefined, columns: columns, rows: filteredRows, topSummaryRows: isOpenAddNewItem ? summaryRows : null, summaryRowHeight: Object.values(formik.errors).some((error) => error) && isSubmited ? 80 : 50, headerRowHeight: filters.enabled ? 70 : undefined }) }), _jsx("datalist", { id: 'ProgramNms', children: developerOptions.map(({ label, value }) => (_jsx("option", { value: value, children: label }, value))) })] }), _jsx(SpinnerContainer, { children: isLoading && _jsx(Spinner, {}) })] }) }) }));
}
