export const ROUTES = {
    login: {
        path: '/login',
    },
    main: {
        path: '/main',
    },
    student: {
        search: {
            path: '/students',
        },
        studentTests: {
            path: '/students/:studentId/student-tests',
        },
        studentTest: {
            path: '/students/:studentId/:testId',
        },
    },
    dataGridPage: {
        path: '/data-grid-page',
    },
    donateGiverPage: {
        path: '/donate-giver',
    },
    donatesMapPage: {
        path: '/donates-map',
    },
    singMacAdress: {
        path: '/sing-mac-address',
    },
};
export const donationRoutes = [
    ROUTES.main.path,
    ROUTES.donateGiverPage.path,
    ROUTES.singMacAdress.path,
    ROUTES.donatesMapPage.path,
];
export const loginRouters = [ROUTES.login.path];
