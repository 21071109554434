import styled, { keyframes } from 'styled-components';
export const Page = styled.div ``;
export const RootContainer = styled.div `
  display: flex;
  flex-direction: column;
  width: 100vw;
  max-height: 100%;

  gap: 10px;

  > .rdg {
    height: 95vh;
  }
`;
export const Toolbar = styled.div `
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
export const FilterColumn = styled.div `
  line-height: 35px;
  padding: 0;

  > div {
    padding-block: 0;
    padding-inline: 8px;

    &:first-child {
      border-block-end: 1px solid var(--rdg-border-color);
    }
  }
`;
export const SpinnerContainer = styled.div `
  position: fixed;
  bottom: 15px;
  align-self: center;
`;
export const AddItemButton = styled.button `
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;

  transition: opacity 0.1s ease;
  cursor: pointer;

  &:hover {
    opacity: 0.5;
  }

  &:active {
    opacity: 0.2;
  }
`;
export const AddButton = styled.button `
  background-color: red;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;

  transition: opacity 0.1s ease;
  cursor: pointer;

  &:hover {
    opacity: 0.5;
  }

  &:active {
    opacity: 0.2;
  }
`;
export const AddItemButtonImage = styled.img `
  width: 30px;
  height: 30px;
`;
export const Input = styled.input `
  width: 100%;
  padding: 4px;
  font-size: 14px;
  border-color: white;
  border-width: 1px;
  border-style: solid;
  border-radius: 5px;
`;
export const AddItemInput = styled.input `
  width: 100%;
  margin-top: 10px;

  padding: 4px;
  font-size: 14px;
  border-color: white;
  border-width: 1px;
  border-style: solid;
  border-radius: 5px;
`;
export const FilterContaier = styled.div `
  display: flex;
  flex-direction: column;
`;
export const AddFilrerContainer = styled.div `
  display: flex;
  flex-direction: column;
  min-height: 100%;

  justify-content: flex-end;
  padding-bottom: 10px;
`;
export const ColumnName = styled.p `
  line-height: 35px;
  margin: 0;
`;
export const spinAnimation = keyframes `
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;
export const Spinner = styled.div `
  border: 4px solid #f3f3f3;
  border-top: 4px solid black;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: ${spinAnimation} 1s linear infinite;
`;
export const NewItemInputContainer = styled.div `
  display: flex;
  flex-direction: column;
`;
export const AddContainer = styled.div `
  display: flex;
  flex-direction: column;

  justify-content: center;
  min-height: 100%;
`;
export const NewItemInputText = styled.p `
  line-height: 35px;
  margin: 0;
`;
export const ErrorText = styled.p `
  line-height: 20px;
  margin: 0;
  color: red;
`;
export const ErrorMessage = styled.h1 `
  color: red;
  font-size: 1.5rem;
  text-align: center;
  padding: 20px;
  background-color: #ffdddd;
  border: 1px solid #ff0000;
  border-radius: 5px;
`;
