import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, ListItem, Popover, } from '@mui/material';
import { memo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { yanuszLogo } from 'src/assets';
import { ArrowDownNewIcon } from 'src/assets/icons/ArrowDownNew';
import { Box } from '@mui/system';
import { CustomButton, FlexBox, ItemButton, ListStyled, Logo, StyledAppBar, StyledToolbar, Title } from './styled';
import Name from './ui/Name';
import { anchorOrigin } from './constants';
import { donationRoutes, ROUTES } from '@/constants/routes';
import usePopoverAnchor from '@/hooks/usePopoverAnchor';
import StorageService from '@/utils/storage';
import { STORAGE_KEYS } from '@/constants/common';
import { useAuth } from '@/hooks/useAuth';
const Header = () => {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { open, anchorEl, handleOpen, handleClose } = usePopoverAnchor();
    const { token } = useAuth();
    const isSerchVRPage = ROUTES.student.search.path === pathname;
    const isLoginPage = ROUTES.login.path === pathname;
    const isMainOrDonatePage = donationRoutes.includes(pathname);
    const isVrHeader = isMainOrDonatePage || isSerchVRPage;
    const isStudentTest = new RegExp(`^(${ROUTES.student.studentTest.path.replace(/:studentId|:testId/g, '\\d+')}|
    ${ROUTES.student.studentTests.path.replace(/:studentId/g, '\\d+')})$`).test(pathname);
    const handleSignOut = () => {
        StorageService.remove(STORAGE_KEYS.token);
        StorageService.remove(STORAGE_KEYS.enteredStudents);
        navigate(ROUTES.login.path);
        handleClose();
    };
    const handleGoBack = () => {
        if (pathname.startsWith('/student') && token) {
            navigate(ROUTES.student.search.path);
        }
        else {
            navigate(-1);
        }
    };
    if (isLoginPage) {
        return _jsx(_Fragment, {});
    }
    return (_jsx(StyledAppBar, { children: _jsxs(StyledToolbar, { children: [isMainOrDonatePage ? (_jsx(Title, { children: pathname === ROUTES.main.path ? ('Ignite') : (_jsx(Button, { onClick: handleGoBack, children: "Ignite" })) })) : (_jsx(Button, { onClick: handleGoBack, children: _jsx(Logo, { src: yanuszLogo, alt: "VR.Narrative" }) })), (isVrHeader || isStudentTest) && (_jsxs(FlexBox, { children: [_jsx(Box, { id: "print-button-portal" }), _jsxs(Box, { children: [_jsxs(CustomButton, { onClick: handleOpen, children: [_jsx(Name, {}), _jsx(ArrowDownNewIcon, {})] }), _jsx(Popover, { open: open, anchorEl: anchorEl, onClose: handleClose, anchorOrigin: anchorOrigin, disableScrollLock: true, children: _jsx(ListStyled, { children: _jsx(ListItem, { disableGutters: true, children: _jsx(ItemButton, { onClick: handleSignOut, children: "Sign Out" }) }) }) })] })] }))] }) }));
};
export default memo(Header);
