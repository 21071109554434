import { jsx as _jsx } from "react/jsx-runtime";
import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import Main from './Donation/Main';
import DonateGiverPage from './Donation/DonateGiver';
import DonatesMap from './Donation/DonatesMap';
import SignMacAdress from './Donation/SignMacAdress';
import StudentTests from './Clasees/StudentTests';
import { ROUTES } from '@/constants/routes';
import DataGridPage from '@/components/DataGridPage';
const Login = lazy(() => import('./Clasees/Login'));
const StudentSearch = lazy(() => import('./Clasees/StudentSearch'));
const Student = lazy(() => import('./Clasees/Student'));
export const PAGES = [
    {
        path: ROUTES.login.path,
        component: _jsx(Login, {}),
    },
    {
        path: ROUTES.dataGridPage.path,
        component: _jsx(DataGridPage, {}),
    },
    {
        path: ROUTES.donateGiverPage.path,
        component: _jsx(DonateGiverPage, {}),
    },
    {
        path: ROUTES.main.path,
        component: _jsx(Main, {}),
    },
    {
        path: ROUTES.donatesMapPage.path,
        component: _jsx(DonatesMap, {}),
    },
    {
        path: ROUTES.singMacAdress.path,
        component: _jsx(SignMacAdress, {}),
    },
    {
        path: '/',
        component: _jsx(Navigate, { to: ROUTES.login.path, replace: true }),
    },
    {
        path: '*',
        component: _jsx(Navigate, { to: ROUTES.login.path, replace: true }),
    },
];
export const PAGES_PROTECTED = [
    {
        path: ROUTES.student.search.path,
        component: _jsx(StudentSearch, {}),
    },
    {
        path: ROUTES.student.studentTests.path,
        component: _jsx(StudentTests, {}),
    },
    {
        path: ROUTES.student.studentTest.path,
        component: _jsx(Student, {}),
    },
];
