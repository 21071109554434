import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { Route, Routes } from 'react-router-dom';
import { ThemeProvider as MuiThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider as ScThemeProvider } from 'styled-components';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import ErrorBoundary from './ErrorBoundary';
import { GlobalStyle } from './styled';
import Layout from './Layout';
import ProtectedRoute from './ProtectedRoute';
import { PAGES, PAGES_PROTECTED } from '@/pages/index';
import theme from '@/constants/theme';
import { ArchiveProvider } from '@/hooks/useArchiveStatus';
const queryClient = new QueryClient();
const App = () => (_jsx(ErrorBoundary, { children: _jsx(QueryClientProvider, { client: queryClient, children: _jsx(StyledEngineProvider, { injectFirst: true, children: _jsx(ArchiveProvider, { children: _jsx(MuiThemeProvider, { theme: theme, children: _jsxs(ScThemeProvider, { theme: theme, children: [_jsx(CssBaseline, {}), _jsx(GlobalStyle, {}), _jsx(Routes, { children: _jsxs(Route, { path: '/', element: _jsx(Layout, {}), children: [PAGES.map(({ path, component }) => (_jsx(Route, { path: path, element: component }, path))), _jsx(Route, { element: _jsx(ProtectedRoute, {}), children: PAGES_PROTECTED.map(({ path, component }) => (_jsx(Route, { path: path, element: component }, path))) })] }) })] }) }) }) }) }) }));
export default memo(App);
