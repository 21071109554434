import { jsx as _jsx } from "react/jsx-runtime";
import React, { createContext, useContext, useState, useCallback, useMemo } from 'react';
const ArchiveContext = createContext(undefined);
export const ArchiveProvider = ({ children }) => {
    const [archiveStatus, setArchiveStatus] = useState(1);
    const handleSetArchiveStatus = useCallback((param) => {
        const statusMap = {
            No: 0,
            Yes: 1,
            Only: 2,
        };
        setArchiveStatus(statusMap[param]);
    }, []);
    const contextValue = useMemo(() => ({
        archiveStatus,
        setArchiveStatus,
        handleSetArchiveStatus,
    }), [archiveStatus, handleSetArchiveStatus]);
    return _jsx(ArchiveContext.Provider, { value: contextValue, children: children });
};
export const useArchiveStatus = () => {
    const context = useContext(ArchiveContext);
    if (!context) {
        throw new Error('useArchiveStatus must be used within an ArchiveProvider');
    }
    return context;
};
