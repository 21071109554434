import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { memo, useCallback, useId, useLayoutEffect, useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { isAxiosError } from 'axios';
import { LockIcon, SmsIcon } from 'src/assets/icons';
import { login } from 'src/api/login';
import { SignInFormWrapper, ErrorMessage, SignInTitle } from './styled';
import { defaultValues, registerScheme } from './lib/validation';
import { ROUTES } from '@/constants/routes';
import InputFormField from '@/ui/InputFormField';
import RouteLink from '@/ui/RouteLink';
import SubmitButton from '@/ui/SubmitButton';
import StorageService from '@/utils/storage';
import { STORAGE_KEYS } from '@/constants/common';
const SignInForm = () => {
    const navigate = useNavigate();
    const loaderId = useId();
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const { handleSubmit, control, formState: { errors, isValid }, setError, setFocus, } = useForm({
        defaultValues,
        resolver: zodResolver(registerScheme),
    });
    useLayoutEffect(() => {
        setFocus('email');
    }, [setFocus]);
    const submit = useCallback(async (data) => {
        if (!isValid)
            return;
        setLoading(true);
        const response = await login({
            ...data,
        });
        setLoading(false);
        if (response && response.toString() !== '0' && !isAxiosError(response)) {
            StorageService.set(STORAGE_KEYS.userId, response);
            navigate(ROUTES.donateGiverPage.path);
        }
        else {
            setError('email', { message: 'Wrong password or email' });
            setError('password', { message: 'Wrong password or email' });
        }
    }, [isValid, navigate, setError]);
    return (_jsx(_Fragment, { children: _jsxs(SignInFormWrapper, { onSubmit: handleSubmit(submit), children: [_jsx(SignInTitle, { children: "Welcome Back!" }), _jsx(Controller, { name: 'email', control: control, render: ({ field: { ref, onChange, ...field } }) => (_jsx(InputFormField, { ...field, inputRef: ref, fullWidth: true, Icon: SmsIcon, onChange: (event) => {
                            onChange(event);
                            setErrorMessage('');
                        }, error: !!errors.email, helperText: errors.email?.message, type: 'email', placeholder: 'Email' })) }), _jsx(Controller, { name: 'password', control: control, render: ({ field: { ref, onChange, ...field } }) => (_jsx(InputFormField, { ...field, inputRef: ref, type: 'password', Icon: LockIcon, fullWidth: true, onChange: (event) => {
                            onChange(event);
                            setErrorMessage('');
                        }, error: !!errors.password, placeholder: 'Password', helperText: errors.password?.message })) }), _jsx(RouteLink, { to: '', color: 'primary', underline: 'hover', children: "Forgot Password?" }), _jsx(SubmitButton, { loaderId: loaderId, loading: loading, width: 292, height: 48, fontWeight: 900, "aria-describedby": loading ? loaderId : undefined, "aria-busy": loading, children: "Sign in" }), errorMessage ? _jsx(ErrorMessage, { children: errorMessage }) : null] }) }));
};
export default memo(SignInForm);
